<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1,2.6h3.2l-7.1,8l8.4,10.9h-6.5l-5.1-6.6l-5.9,6.6H1.8l7.6-8.5l-8-10.3h6.7l4.6,6L18.1,2.6z M17,19.5h1.8
	L7.1,4.4H5.2L17,19.5z"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts" setup></script>
